import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseUrlService } from './base-url.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private http: HttpClient, private api: BaseUrlService) {}

    url = this.api.endpoint;
    token = localStorage.getItem('accessToken');
    query = 'admin/notifications';
    headers = new HttpHeaders({
        'content-type': 'application/json',
        'x-api-key': 'eyJhbGciOiJIUzI1NiJ9.ZGFpa3UtYXBpLWRldg.Q3OhTw07V8M49NiZ0vJvPUKbvbRRV5eqefMc88fHXUw',
        Authorization: this.token
    });

    //get notification data on role Admin
    getLimit() {
        const limit = 5;
        return this.http.get(this.url + this.query + `?seen=false&limit=${limit}`, { headers: this.headers });
    }

    // Get all notifications and update all unseen to be true.
    getSeenAll() {
        return this.http.get(this.url + this.query + '?seen=true&limit=5', { headers: this.headers });
    }

    // Get all notifications and update all unseen tobe true.
    getAll(offset: number) {
        return this.http.get(this.url + this.query + `?seen=true&limit=10&offset=${offset}`, { headers: this.headers });
    }

    getOne(id) {
        return this.http.get(this.url + this.query + `/${id}`, { headers: this.headers });
    }
}
