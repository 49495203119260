import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from './base-url.service';

import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from '../models/user.model';
import { NotificationService } from './notification.service';
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {
    url = this.api.endpoint;
    query = 'auth/register-push-token';
    headers = this.api.headers;

    currentMessage = new BehaviorSubject(null);

    seenChange: Subject<number> = new Subject<number>();

    constructor(
        private api: BaseUrlService,
        private http: HttpClient,
        private angularFireMessaging: AngularFireMessaging,
        private notificationService: NotificationService
    ) {
        this.angularFireMessaging.messaging.subscribe(_messaging => {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        });
    }

    /**
     * request permission for notification from firebase cloud messaging
     *
     * @param userId userId
     */
    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            token => {
                // Permission Granted
                // console.log(token);
                const currentUser: User = JSON.parse(localStorage.getItem('user'));
                const phone = currentUser.phoneNumber;
                const registerPushNotif = {
                    phoneNumber: phone,
                    deviceId: phone,
                    token: token
                };
                this.registerPushToken(registerPushNotif).subscribe(
                    () => {
                        console.log('Subscribed');
                    },
                    err => {
                        console.log('already subscribed for push notification');
                    }
                );
            },
            err => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    /**
     * hook method when new notification received in foreground
     */
    totalUnseen: number;
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(payload => {
            this.notificationService.getLimit().subscribe((res: any) => {
                this.totalUnseen = res.metadata.totalUnSeen;
                this.seenChange.next(res.metadata.totalUnSeen);
            });
            this.currentMessage.next(payload);
        });
    }

    registerPushToken(data) {
        return this.http.put(this.url + this.query, data, { headers: this.headers });
    }
}
