import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from './base-url.service';
@Injectable({
    providedIn: 'root'
})
export class ProvinceService {
    constructor(private http: HttpClient, private api: BaseUrlService) {}

    url = this.api.endpoint;
    headers = this.api.headers;
    get() {
        const query = 'admin/provinces?lang=en';
        return this.http.get(this.url + query, { headers: this.headers });
    }
}
