// for dealer list at dashboard
export class Dealer {
    role = '';
    status = '';
    hasPassword = true;
    hasComment = false;
    isArchived = true;
    _id = '';
    name = '';
    phoneNumber = '';
    idCardNumber = 0;
    idCardImageUrl = '';
    imageUrl = '';
    province = '';
    district = '';
    commune = '';
    email = '';
    patentNumber = 0;
    shopImageUrl = '';
    patentImageUrl = '';
    saleRepId = '';
    approvedAt = '';
    approvedBy = '';
    rejectedAt = '';
    rejectedBy = '';
    deletedAt = '';
    deletedBy = '';
    createdAt = '';
    updatedAt = '';
    blacklistedAt: '';
    blacklistedBy: '';
    __v = 0;
}
