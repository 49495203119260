import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from './base-url.service';

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    constructor(private http: HttpClient, private api: BaseUrlService) {}

    url = this.api.endpoint;

    upload(files: FileList, path?: string) {
        const data = new FormData();
        !path ? (path = 'images') : '';

        for (let i = 0; i <= files.length; i++) {
            data.append('files', files[i]);
        }
        data.append('type', 'any');

        return this.http.post(this.url + `uploads/${path}`, data, {
            headers: { authorization: localStorage.getItem('accessToken') }
        });
    }

    uploadOneFile(file: File, path?: string) {
        const data = new FormData();
        !path ? (path = 'images') : '';

        data.append('files', file);
        data.append('type', 'any');

        return this.http.post(this.url + `uploads/${path}`, data, {
            headers: { authorization: localStorage.getItem('accessToken') }
        });
    }
}
